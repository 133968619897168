"use client"
import { useTranslation } from "react-i18next"

import { ContentContainer } from "./components/ContentContainer"
import styles from "./not-found.module.scss"

import { Button } from "@/components/common/button"
import { Headline } from "@/components/common/headline"
import { Lottie } from "@/components/common/lottie"

import { create } from "@/helpers/bem"

const bem = create(styles, "NotFound")

export default function NotFound() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  return (
    <ContentContainer>
      <div className={bem()}>
        <Lottie animationName="NotFound" className={bem("animation")} />
        <Headline highlight align="center" size={5} type="h1" variant="primary">
          {t("pages.notFound.title")}
        </Headline>
        <Headline align="center" size="lg" type="h2" variant="inherit">
          {t("pages.notFound.content")}
        </Headline>
        <Button
          aria-label={t("pages.notFound.button")}
          className={bem("button")}
          color="primary"
          title={t("pages.notFound.button")}
          variant="contained"
          redirect={{
            href: `/${locale}`,
          }}
        />
      </div>
    </ContentContainer>
  )
}
