"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import { ReactNode, memo, useMemo } from "react"

import styles from "./ContentContainer.module.scss"

import { create } from "@/helpers/bem"
import { hexToRGB } from "@/helpers/hexToRGB"

const bem = create(styles, "ContentContainer")

export type ContentContainerBackgroundVariant =
  | "inherit"
  | "primary"
  | "primaryLight"
  | "secondary"
  | "teritary"
  | "black"
  | "gray"
  | "darkGray"
  | "lightGray"
  | "warning"
  | "error"
  | "info"
  | "white"

export type ContentContainerBackgroundStyle = {
  backgroundColor?: ContentContainerBackgroundVariant
  backgroundOpacity?: number
  backgroundColorDark?: ContentContainerBackgroundVariant
  backgroundOpacityDark?: number
}

export type ContentContainerSpacing = "xl" | "lg" | "md" | "sm" | "xs" | "none"

export type ContentContainerProps = {
  className?: string
  spacing?: ContentContainerSpacing
  contentClassName?: string
  fullWidth?: boolean
  backgroundStyle?: ContentContainerBackgroundStyle
  children: ReactNode
}

export const ContentContainer = memo(
  ({
    className,
    contentClassName,
    spacing = "xl",
    fullWidth,
    backgroundStyle,
    children,
  }: ContentContainerProps) => {
    const theme = useTheme()
    const isDark = isEqual(theme?.palette?.mode, "dark")
    const backgroundColor = useMemo(() => {
      let color = backgroundStyle?.backgroundColorDark
      let opacity =
        backgroundStyle?.backgroundOpacityDark ??
        backgroundStyle?.backgroundOpacity ??
        1
      if (isDark && color && color !== "inherit") {
        return hexToRGB(theme.palette?.[color]?.main, opacity)
      }
      color = backgroundStyle?.backgroundColor
      if (color && opacity) {
        return hexToRGB(theme.palette?.[color]?.main, opacity)
      }
      return undefined
    }, [isDark, theme.palette, backgroundStyle])
    return (
      <section
        className={bem(
          undefined,
          {
            fullWidth: !!fullWidth,
            "has-background": !!backgroundStyle?.backgroundColor,
          },
          className,
        )}
        style={
          backgroundStyle && {
            backgroundColor,
          }
        }
      >
        <div
          className={bem(
            "content",
            {
              [`spacing-${spacing}`]: !isEqual(spacing, "xl"),
              fullWidth: !!fullWidth,
            },
            contentClassName,
          )}
        >
          {children}
        </div>
      </section>
    )
  },
)

ContentContainer.displayName = "ContentContainer"
